import React, { useContext, useEffect, useState } from 'react'
import * as VoteWidgetStyles from '../../../components/MuddyAwards/VoteWidget/VoteWidget.module.scss'
import { graphql, navigate } from 'gatsby'
import {
  AdStarBlock,
  Category,
  GeneralSettings,
  LiveVoteDataSingle,
  SponsoredCategories
} from '../../../types'
import { IMenu } from '../../../graphql/entities/Menu'
import TopBanner from '../../../components/Banners/TopBanner/TopBanner'
import Content from '../../../components/Content/Content'
import Footer from '../../../components/Footer/Footer'
import Header from '../../../components/Header/Header'
import AwardsHeader from '../../../components/MuddyAwards/AwardsHeader'
import AwardsMenu from '../../../components/MuddyAwards/AwardsMenu'
import Breadcrumb from '../../../components/organisms/breadcrumb/Breadcrumb'
import PageMeta from '../../../components/PageMeta/PageMeta'
import MartiniGlass from '../../../components/MuddyAwards/MartiniGlass/MartiniGlass'
import MediaQuery from 'react-responsive'
import { MuddyAwardsContext, MuddyAwardsStates } from '../../../lib/utils'
import Leaderboard from '../../../components/Banners/Leaderboard/Leaderboard'

const getNextQuarterHour = () => {
  const now = new Date()
  const minutes = now.getMinutes()
  const nextQuarter = 15 * Math.ceil((minutes + 1) / 15)
  const nextTime = new Date(now)

  if (nextQuarter >= 60) {
    nextTime.setHours(now.getHours() + 1)
    nextTime.setMinutes(0)
  } else {
    nextTime.setMinutes(nextQuarter)
  }

  // Attempt to format without leading zero for the hour (may not work in all environments)
  let formattedTime = nextTime.toLocaleTimeString([], {
    hour: 'numeric', // Changed to 'numeric' to potentially reduce leading zero
    minute: '2-digit',
    hour12: true
  })

  // Workaround for environments where 'numeric' doesn't remove leading zero or shows "00" for midnight/noon
  const timeParts = formattedTime.match(/(\d+):(\d+)(.*)/)
  if (timeParts) {
    const [_, hour, minute, amPm] = timeParts
    let hourInt = parseInt(hour, 10)

    // Adjust hour for 12 AM/PM cases
    if (hourInt === 0) {
      hourInt = 12 // Midnight or Noon should be '12'
    }

    formattedTime = `${hourInt}:${minute}${amPm}`
  }

  return formattedTime
}

export const query = graphql`
  query {
    wpgraphql {
      generalSettings {
        title
        siteId
        egSettingTwitter
        url
      }
      awardsCategories {
        title
        image
        description
        id
        finalists {
          meta_a
          meta_b
          meta_c
          meta_d
          meta_e
        }
      }
      sponsoredCategories {
        slug
        title
      }
      adStarCodes {
        id
        adslot
      }
      awardsMenu: menu(id: "Awards", idType: NAME) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      category(id: "muddy-awards", idType: SLUG) {
        sponsoredBy
        sponsoredLogo
      }
    }
  }
`
export interface AwardCat {
  id: string
  title: string
  image: string
  description: string
  finalists?: {
    meta_a: string
    meta_b: string
    meta_c: string
    meta_d: string
    meta_e: string
    winning_finalist?: string
  }
}
interface LiveResultsProps {
  data: {
    wpgraphql: {
      generalSettings: GeneralSettings
      adStarCodes: AdStarBlock[]
      awardsMenu: {
        menuItems: {
          nodes: IMenu[]
        }
      }
      category: Category
      awardsCategories: AwardCat[]
      sponsoredCategories: SponsoredCategories[]
    }
  }
}

const LiveResults = ({
  data: {
    wpgraphql: {
      generalSettings,
      adStarCodes,
      awardsMenu: {
        menuItems: { nodes: subMenu }
      },
      category,
      awardsCategories
    }
  }
}: LiveResultsProps) => {
  const AwardsContext = useContext(MuddyAwardsContext)
  const [chosenCategory, setChosenCategory] = useState<string>()
  const siteName = generalSettings.title ?? ''
  const [siteResults, setSiteResults] = useState<LiveVoteDataSingle[]>()
  const [lastModifiedDate, setLastModifiedDate] = useState<string>('')
  const [nextUpdateTime, setNextUpdateTime] = useState<string>('')

  const convertDate = (unixDate: string) => {
    const date = new Date(parseInt(unixDate) * 1000)
    const hours = date.getHours()
    const minutes = '0' + date.getMinutes()
    const day = date.getDate()
    const month = date.toLocaleString('default', { month: 'short' }) // 'Apr' for April
    const year = date.getFullYear()

    // Adding suffix to day
    let daySuffix
    switch (day) {
      case 1:
      case 21:
      case 31:
        daySuffix = 'st'
        break
      case 2:
      case 22:
        daySuffix = 'nd'
        break
      case 3:
      case 23:
        daySuffix = 'rd'
        break
      default:
        daySuffix = 'th'
    }

    return (
      hours +
      ':' +
      minutes.substr(-2) +
      ', ' +
      day +
      daySuffix +
      ' ' +
      month +
      ' ' +
      year
    )
  }

  const getLiveResults = async () => {
    const response = await (
      await fetch(
        `https://dev.muddystilettos.co.uk/wp-json/muddy/muddy-votes?t=${Date.now()}`
      )
    ).json()

    const { results, modifiedDate } = response.data
    const filteredResults: LiveVoteDataSingle[] = results[siteName]
    setLastModifiedDate(convertDate(modifiedDate))
    setSiteResults(filteredResults)
  }

  useEffect(() => {
    // Function to calculate the delay until the next quarter hour
    const calculateDelay = () => {
      const now = new Date()
      const minutes = now.getMinutes()
      const nextQuarterMinute = 15 * Math.ceil(minutes / 15)
      const delayUntilNextQuarter =
        ((nextQuarterMinute % 60) - minutes) * 60000 -
        now.getSeconds() * 1000 -
        now.getMilliseconds()

      return delayUntilNextQuarter >= 0
        ? delayUntilNextQuarter
        : delayUntilNextQuarter + 15 * 60000
    }

    getLiveResults()
    setNextUpdateTime(getNextQuarterHour())

    const timeoutId = setTimeout(function tick() {
      getLiveResults()
      setNextUpdateTime(getNextQuarterHour())
      setTimeout(tick, calculateDelay())
    }, calculateDelay())

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    if (!siteResults || siteResults === undefined) return
    if (!chosenCategory || chosenCategory === undefined)
      setChosenCategory(Object.keys(siteResults)[0])
  }, [siteResults])

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: 'Muddy Stilettos Awards 2024',
          description:
            'The Muddy Stilettos Awards are the most coveted awards for independent lifestyle businesses across 28 counties, and totally FREE to enter!',
          image:
            'https://muddystilettos.co.uk/images/awards/Awards_Badge_2024.gif'
        }}
      />
      <Header />
      <TopBanner ads={adStarCodes} pageType={`awards`} />
      <Leaderboard adSlot={'TopLB'} />
      {category && (
        <AwardsHeader category={category} generalSettings={generalSettings} />
      )}
      {subMenu && (
        <AwardsMenu subMenu={subMenu} generalSettings={generalSettings} />
      )}
      <div>
        <Content>
          <Breadcrumb
            postType={`awards`}
            postTitle={'/ Live Update'}
            postURI={'/awards/live-results/'}
          />
          <h1 dangerouslySetInnerHTML={{ __html: 'Live Update' }} />
          {AwardsContext !== MuddyAwardsStates.VOTING ? (
            <div style={{ textAlign: 'center' }}>
              <p>
                Voting has now closed. Winners will be announced 2nd May 2024 @
                1pm. Watch this space!
              </p>
            </div>
          ) : (
            <>
              <div style={{ textAlign: 'center' }}>
                <p>
                  Follow the live voting in the Regional Finals as they come in.
                </p>
                <p>
                  <strong>
                    Latest results will be refreshed every 15 minutes.
                  </strong>
                </p>
                <p>The next update will be at:</p>
                <p style={{ fontSize: '26px', color: 'rgb(231, 44, 94)' }}>
                  <strong title={`Last updated: ${lastModifiedDate}`}>
                    {nextUpdateTime}
                  </strong>
                </p>
                <p>Every vote counts!</p>
              </div>
              <div
                className={VoteWidgetStyles.voteButton}
                onClick={() => navigate('/vote')}
              >
                Vote
              </div>
            </>
          )}
        </Content>
        {AwardsContext === MuddyAwardsStates.VOTING ? (
          <>
            <MediaQuery minWidth={0}>
              <div id={`el_top`} className={VoteWidgetStyles.JumpNav}>
                <select
                  name={`JumpNav`}
                  defaultValue=""
                  onChange={e => setChosenCategory(e.target.value)}
                >
                  <option disabled value="">
                    Select a category to view
                  </option>
                  {siteResults &&
                    Object.entries(siteResults).map(([key]) => (
                      <option
                        key={key}
                        value={key}
                        dangerouslySetInnerHTML={{
                          __html:
                            awardsCategories.find(c => c.id === key)?.title ??
                            ''
                        }}
                      />
                    ))}
                </select>
              </div>
              {chosenCategory && siteResults && (
                <div className={VoteWidgetStyles.MartiniContainer}>
                  <MartiniGlass
                    awardsCategories={awardsCategories}
                    resultObject={
                      siteResults[chosenCategory as unknown as number]
                    }
                    category={chosenCategory}
                  />
                </div>
              )}
            </MediaQuery>
            {/* <MediaQuery minWidth={769}>
              <div
                className={VoteWidgetStyles.BackToTopButton}
                onClick={() => backToTop()}
                title={'Back to Top'}
                aria-label={`Back to Top`}
                role="button"
              >
                <img src={`/images/arrow-down.svg`} />
              </div>

              <div id={`el_top`} className={VoteWidgetStyles.JumpNav}>
                <select
                  name={`JumpNav`}
                  defaultValue=""
                  onChange={e => jumpToCategory(e.target.value.toString())}
                >
                  <option disabled value="">
                    Select a category to jump right to it
                  </option>
                  {siteResults &&
                    Object.entries(siteResults).map(([key]) => (
                      <option key={key} value={key}>
                        {awardsCategories.find(c => c.id === key)?.title}
                      </option>
                    ))}
                </select>
              </div>
              <div className={VoteWidgetStyles.MartiniContainer}>
                {siteResults &&
                  Object.entries(siteResults).map(([key, value]) => (
                    <MartiniGlass
                      awardsCategories={awardsCategories}
                      resultObject={value}
                      category={key}
                      key={key}
                    />
                  ))}
              </div>
            </MediaQuery> */}
          </>
        ) : (
          <div
            className={VoteWidgetStyles.MartiniContainer}
            style={{ gridTemplateColumns: '1fr' }}
          >
            <p>Voting opens soon...</p>
          </div>
        )}
      </div>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default LiveResults
