import Layers from '../../../lib/data/MartiniValues'
import React, { useRef, useEffect, Dispatch, SetStateAction } from 'react'
import { useSpring, easings, animated } from 'react-spring'
interface IPourDetails {
  pourDetails: {
    top: number
    colour: string
  }
  setPourDetails: Dispatch<
    SetStateAction<{ top: number; colour: string } | undefined>
  >
}
const Pour: React.FC<IPourDetails> = ({ pourDetails, setPourDetails }) => {
  const path = useRef<SVGPathElement>(null)

  const [{ x }, set] = useSpring(() => ({
    x: 0,
    config: {
      duration: 1200
    },
    easing: easings.easeInBounce
  }))

  useEffect(() => {
    set({ x: 1200 })

    setTimeout(() => setPourDetails(undefined), 2000)
  }, [])

  if (!pourDetails) return null
  return (
    <>
      <animated.path
        d={x.to({
          range: [0, 200, 400, 600, 800, 1000, 1200],
          output: [...Layers(100, pourDetails.top).bottomPour]
        })}
        stroke={pourDetails.colour}
        fill={pourDetails.colour}
        strokeWidth={0}
        fillOpacity={1}
        ref={path}
      />
    </>
  )
}

export default Pour
