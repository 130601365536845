import React, { useEffect, useState } from 'react'
import MartiniLayer from './MartiniLayer'
import Pour from './Pour'
import { IVotingData } from './MartiniGlass'

interface DataProps {
  voteData: IVotingData
  triggerPour?: number
}

interface IPourDetails {
  top: number
  colour: string
}

const WaveColours = ['#ffe55c', '#ff9d00', '#ff6b54', '#ea4e71', '#cb70b3']

const AnimatedLayers: React.FC<DataProps> = ({ voteData, triggerPour }) => {
  const [pourDetails, setPourDetails] = useState<IPourDetails | undefined>()

  useEffect(() => {
    let whoIsPouring
    if (triggerPour !== undefined) {
      whoIsPouring = Object.values(voteData).filter(
        (_d, i) => i === triggerPour
      )

      setPourDetails({
        top: whoIsPouring[0]?.top,
        colour: WaveColours[triggerPour]
      })
    }
  }, [triggerPour])

  if (!voteData) return null

  return (
    <>
      <g className="wavy" clipPath="url(#martiniGlassClip)">
        {voteData.a &&
          voteData.a.top !== undefined &&
          voteData.a.percentage && (
            <MartiniLayer
              delay={400}
              colour={WaveColours[0]}
              percentage={voteData.a.percentage}
              top={voteData.a.top}
            />
          )}
        {pourDetails !== undefined && pourDetails.colour === WaveColours[0] && (
          <Pour setPourDetails={setPourDetails} pourDetails={pourDetails} />
        )}
        {voteData.b &&
          voteData.b.top !== undefined &&
          voteData.b.percentage && (
            <MartiniLayer
              delay={300}
              colour={WaveColours[1]}
              percentage={voteData.b.percentage}
              top={voteData.b.top}
            />
          )}
        {pourDetails !== undefined && pourDetails.colour === WaveColours[1] && (
          <Pour setPourDetails={setPourDetails} pourDetails={pourDetails} />
        )}
        {voteData.c &&
          voteData.c.top !== undefined &&
          voteData.c.percentage && (
            <MartiniLayer
              delay={200}
              colour={WaveColours[2]}
              percentage={voteData.c.percentage}
              top={voteData.c.top}
            />
          )}
        {pourDetails !== undefined && pourDetails.colour === WaveColours[2] && (
          <Pour setPourDetails={setPourDetails} pourDetails={pourDetails} />
        )}
        {voteData.d &&
          voteData.d.top !== undefined &&
          voteData.d.percentage && (
            <MartiniLayer
              delay={100}
              colour={WaveColours[3]}
              percentage={voteData.d.percentage}
              top={voteData.d.top}
            />
          )}
        {pourDetails !== undefined && pourDetails.colour === WaveColours[3] && (
          <Pour setPourDetails={setPourDetails} pourDetails={pourDetails} />
        )}
        {voteData.e &&
          voteData.e.top !== undefined &&
          voteData.e.percentage && (
            <MartiniLayer
              delay={0}
              colour={WaveColours[4]}
              percentage={voteData.e.percentage}
              top={voteData.e.top}
            />
          )}
        {pourDetails !== undefined && pourDetails.colour === WaveColours[4] && (
          <Pour setPourDetails={setPourDetails} pourDetails={pourDetails} />
        )}
      </g>
    </>
  )
}
export default AnimatedLayers
